<template>
  <div class="upload-file">
    <div class="pt-2">
      <b-form-file
        @change="onSelectFile"
        v-model="file"
        ref="file-input"
        class="d-none"
        id="file-input"
        :accept="acceptFile"
      ></b-form-file>
      <b-input-group>
        <b-input-group-prepend>
          <b-button
            :disabled="btnDisable"
            variant="secondary"
            @click="onOpenFile('file-input')"
            v-b-tooltip.hover
            title="Chọn file"
            ><i class="fas fa-paperclip"></i
          ></b-button>
        </b-input-group-prepend>

        <b-form-input
          type="text"
          :disabled="btnDisable"
          v-model="textFile"
          placeholder="Hãy chọn một tập tin"
          @click="onOpenFile('file-input')"
          v-on:keydown.prevent
          style="caret-color: transparent; cursor: pointer"
          autocomplete="off"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            variant="secondary"
            :disabled="!file"
            v-b-tooltip.hover
            @click="uploadFile"
            title="Tải file"
            ref="up_excel"
          >
            <i class="fas fa-upload text-primary"></i>
          </b-button>
          <b-button
            variant="secondary"
            :disabled="!file"
            @click="onClearFiles"
            v-b-tooltip.hover
            title="Xoá file"
            ><i class="fas fa-trash text-danger"></i
          ></b-button>
        </b-input-group-append>
      </b-input-group>
      <label class="description" style="font-size: 10px">
        Chỉ nhận file csv, xls, xlsx và nhỏ hơn 5MB</label
      >
    </div>
    <div v-show="file" class="mt-4">
      <b-progress :max="100">
        <b-progress-bar
          :value="processBar"
          :label="`${processBar.toFixed(2)}%`"
        ></b-progress-bar>
      </b-progress>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import axios from 'axios';
import { BASE_URL } from '@/utils/constants';
import {
  convertPrice,
  delay,
  makeToastSuccess,
  makeToastFaile,
} from '@/utils/common';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import * as ENUM_NAME from '@/utils/enum-name';
import * as ENUM from '@/utils/enum';

const MAX_SIZE = 5 * 1024 * 1024;

const validExcelMime = [
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export default {
  props: ['cmdUrl'],
  data() {
    return {
      ENUM,
      ENUM_NAME,
      onLoadingList: false,
      file: null,
      btnDisable: false,
      processBar: 0,
      textFile: '',
      isBusy: false,
      acceptFile: validExcelMime.join(),
    };
  },
  computed: {},
  methods: {
    delay,
    convertPrice,
    onOpenFile(name) {
      document.getElementById(name).click();
    },
    onSelectFile(event) {
      const files = event.target.files[0];

      if (!files) {
        this.textFile = '';
        return;
      }

      this.processBar = 0;
      if (!/(xls|xlsx|csv)$/.test(this.getExtension(files.name))) {
        this.onClearFiles();
        return makeToastFaile(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls .xlsx hoặc csv'
        );
      } else if (files.size > MAX_SIZE) {
        this.onClearFiles();
        return makeToastFaile('Chỉ nhận file nhỏ hơn 5MB');
      }

      this.importItems = [];
      this.file = files;
      this.textFile = files.name;
    },
    uploadFile() {
      if (!this.cmdUrl) {
        return makeToastFaile('Vui lòng truyền đường dẫn tải file!');
      }
      if (!this.btnDisable) {
        this.btnDisable = true;
        this.processBar = 0;
        const submitButton = this.$refs['up_excel'];
        this.$refs['up_excel'].innerHTML = '';
        submitButton.classList.add(
          'spinner',
          'spinner-primary',
          'spinner-md',
          'spinner-center',
          'px-7'
        );
        let formData = new FormData();
        const file = this.file;

        formData.append('file', file);

        const url = BASE_URL + this.cmdUrl;

        axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data;',
            },
            timeout: 10000 * 60,
          })
          .then((rs) => {
            this.processBar = 100;
            this.btnDisable = false;
            this.$nprogress.done();
            this.$refs['up_excel'].innerHTML =
              '<i class="fas fa-upload text-primary"></i>';
            submitButton.classList.remove(
              'spinner',
              'spinner-primary',
              'spinner-md',
              'spinner-center',
              'px-7'
            );

            if (rs.data.status) {
              this.$emit('send-file', rs.data.data, this.textFile);
            }
            makeToastSuccess('Đọc file thành công');
          })
          .catch((error) => {
            setTimeout(() => {
              this.btnDisable = false;
              this.$refs['up_excel'].innerHTML =
                '<i class="fas fa-upload text-primary"></i>';
              this.$nprogress.done();
              submitButton.classList.remove(
                'spinner',
                'spinner-primary',
                'spinner-md',
                'spinner-center',
                'px-7'
              );
              if (error) {
                const { message } = error.response.data;
                makeToastFaile(message);
              } else {
                makeToastFaile(
                  'Vui lòng chọn lại file hoặc kiểm tra kết nối mạng'
                );
              }
            }, 500);
          });
      } else {
        makeToastFaile('File đang được tải lên ...');
      }
    },
    getExtension(fname) {
      const pos = fname.lastIndexOf('.');
      const strlen = fname.length;
      let extension = '';
      if (pos != -1 && strlen != pos + 1) {
        let ext = fname.split('.');
        let len = ext.length;
        extension = ext[len - 1].toLowerCase();
      } else {
        return makeToastFaile('File không đúng định dạng');
      }
      return extension;
    },
    onClearFiles() {
      this.file = null;
      this.processBar = 0;
      this.$refs['file-input'].reset();
      this.textFile = '';
      this.$emit('clear-file');
    },
  },
};
</script>
